import {
  AfterLoginGoTo,
  idRegex,
  OAuthState,
  resultStatuses,
  sessionKeys,
} from "./constants";
import { OneHourInMillis } from "./constants";
import { OneMinuteInMillis } from "./constants";
import { OneSecondInMillis } from "./constants";
import { SessionExpirationVar } from "./constants";
import _, { isNumber } from "lodash";

/**
 * Formatter for dates and times. Resulting format is Day Month Year, Time.
 *
 * @param {string} isoTimestamp: The ISO timestamp to reformat.
 * @returns string: Formatted date and time.
 */
export const getDateTimeString = (isoTimestamp) => {
  if (isoTimestamp) {
    let dateTime;
    if (!_.endsWith(isoTimestamp, "+0000")) {
      dateTime = new Date(`${isoTimestamp}+0000`);
    } else {
      dateTime = new Date(isoTimestamp);
    }
    return `${dateTime.toDateString()}, ${dateTime.toLocaleTimeString()}`;
  }
  return "---";
};

/**
 * Filters through all object key value pairs and deletes
 * a specific pair by it's key.
 *
 * @param {object} obj: object to be edited.
 * @param {string} deleteKey: the key of the specified pair.
 * @returns new object without specified key value pair
 */

export const destruct = (obj, deleteKey) => {
  return Object.keys(obj)
    .filter((key) => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = obj[current];
      return result;
    }, {});
};

/**
 * Converts a date object into a UTC timestamp string without the time component.
 * @param {Date} dateObj The Date object to convert into a timestamp.
 * @returns The UTC timestamp string without the time component.
 */
export const getUTCDateString = (dateObj) => {
  let dateComponents = dateObj.toUTCString().split(" ");
  // The last two components will be the timezone and the time string
  dateComponents.pop();
  dateComponents.pop();
  return dateComponents.join(" ");
};

/**
 * Capitalizes the first letter of a word.
 *
 * @param {string} str A string to replace the first character of.
 * @returns The formatted string.
 */
export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Escapes special characters from a string being used to craft
 * a regular expression.
 *
 * @param {string} text A string to regex escape.
 * @returns A string that has been re-formatted.
 */
export const escapeRegExp = (text) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

/**
 * Function primarily used for masking sensitive JSON values.
 * For each character split from the key's value (string), it's replaced
 * with the second param (character).
 *
 * @param {string} str the string to be edited or masked.
 * @param {string} char the character that replace each character in the string.
 * @returns A string that has been re-formatted.
 *
 */

export const replaceAllChars = (str, char) => {
  if (str !== undefined) {
    let s = str.split("");
    for (let i = 0; i < s.length; i++) {
      s[i] = char;
    }
    return s.join("");
  }
};

/**
 * @TODO This function should not be needed upon completion of
 * DAST-1001 (https://jira-eng-rtp1.cisco.com/jira/browse/DAST-1001)
 * values that are considered sensitive enough to need to be masked should not
 * be included in plain text responses from the API.
 *
 * Masks sensitive parameters in a config.
 *
 * @param {*} unmaskedData The unmasked config.
 * @returns A config with the sensitive parameters hidden.
 */
export const maskedConfig = (unmaskedData) => {
  let maskedData = {
    ...unmaskedData,
    auth_access_token: replaceAllChars(unmaskedData?.auth_access_token, "*"),
    password: replaceAllChars(unmaskedData?.password, "*"),
  };
  delete maskedData.APP_SERVICE_TOKEN;
  delete maskedData.HELIOS_API_URL;
  return JSON.stringify(maskedData, null, 4);
};

/**
 * Scrolls to an anchor using react's useRef hook and the
 * scrollIntoView browser method.
 *
 * @param ref A react hook returning a mutable object referencing a specific element.
 * useRef: https://reactjs.org/docs/hooks-reference.html#useref
 * @param {string} block One of start, center, end, or nearest.
 */
export const scroller = (ref, block) => {
  if (!ref.current?.scrollIntoView) return;
  ref.current.scrollIntoView({
    behavior: "smooth",
    block: `${block}`,
  });
};

/**
 * Capitalizes the first letter of a word.
 *
 * @param {number} idx The index of the item to be removed.
 * @param {array} list An array of list items.
 * @returns The updated list after the item has been removed.
 */
export const removeItemFromList = (idx, list) => {
  let listCopy = [...list];
  if (idx < 0 || idx >= listCopy.length) {
    return listCopy;
  }
  listCopy.splice(idx, 1);
  return listCopy;
};

/**
 * A function to pass to sort a list of date strings.
 *
 * @param {string} a A timestamp string.
 * @param {string} b A timestamp string that follows a.
 * @returns The difference between the dates
 */
export const sortDate = (a, b) => {
  return new Date(a) - new Date(b);
};

/**
 * A function pass to sort a list of strings alphabetically.
 *
 * @param {string} a A string.
 * @param {string} b A string that follows a.
 * @returns A number, 1, 0, or -1 representing which element should precede which.
 *          https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
 */
export const sortAlphabetically = (a, b) => {
  return a.localeCompare(b);
};

/**
 * Gets the expiration from local storage.
 *
 * @returns The value stored in local storage.
 */
export const getExpiration = () => {
  return localStorage.getItem(SessionExpirationVar);
};

/**
 * Sets the session expiration in local storage.
 *
 * @param {string} timestamp A timestamp to indicate the session expiration.
 */
export const setExpiration = (timestamp) => {
  localStorage.setItem(SessionExpirationVar, timestamp);
};

/**
 * Gets the path to go to after login from local storage.
 *
 * @returns The value stored in local storage.
 */
export const getAfterLoginGoTo = () => {
  return localStorage.getItem(AfterLoginGoTo);
};

/**
 * Sets the path to go to after login.
 *
 * @param {string} path The path to go to after login.
 */
export const setAfterLoginGoTo = (path) => {
  localStorage.setItem(AfterLoginGoTo, path);
};

/**
 * Gets the oauth state from session storage.
 *
 * @returns The value stored in session storage.
 */
export const getOAuthState = () => {
  return sessionStorage.getItem(OAuthState);
};

/**
 * Sets the oauth state in session storage.
 * Clears storage if being set to null/undefined.
 *
 * @param {string} state The state to store.
 */
export const setOAuthState = (state) => {
  if (!state) {
    sessionStorage.removeItem(OAuthState);
  } else {
    sessionStorage.setItem(OAuthState, state);
  }
};

/**
 * Gets the total count of apps from the values returned in the _links.
 *
 * @returns The total number of apps.
 */
export const getTotalCountFromLinks = (links) => {
  if (links) {
    const url = new URL(links.last);
    return (
      parseInt(url.searchParams.get("page")) *
      parseInt(url.searchParams.get("pageSize"))
    );
  }
  return 0;
};

/**
 * Transforms a number string into a number.
 *
 * @param {string} value A string value to be transformed into a number.
 * @returns The number or an empty string.
 */
export const getNumberFromString = (value) => {
  let newValue = value === "" ? "" : Number(value);
  return newValue;
};

/**
 * Builds a time delta string in the format "1h 5m 3s"
 *
 * @param {number} hours The number of hours passed.
 * @param {number} mins The number of minutes.
 * @param {number} seconds The number of seconds.
 * @returns The time delta string in the expected format.
 */
export const buildTimeDeltaString = (hours, mins, seconds) => {
  let timeDeltaString = "";
  if (hours > 0) {
    timeDeltaString += `${hours}h `;
  }
  if (mins > 0) {
    timeDeltaString += `${mins}m `;
  }
  timeDeltaString += `${seconds}s`;
  return timeDeltaString;
};

/**
 * Gets a time delta string from two dates calculating the
 * number of hours then minutes then seconds.
 *
 * @param {string} laterDateTime The later timestamp of the two.
 * @param {string} earlierDateTime The earlier timestamp of the two.
 * @returns A string representing the difference if it is calculable.
 */
export const getTimeDeltaString = (laterDateTime, earlierDateTime) => {
  if (laterDateTime && earlierDateTime) {
    const diffInMillis = new Date(laterDateTime) - new Date(earlierDateTime);
    if (_.isNumber(diffInMillis)) {
      let millisLeft = diffInMillis;
      const hours = Math.floor(millisLeft / OneHourInMillis);
      millisLeft -= hours * OneHourInMillis;
      const mins = Math.floor(millisLeft / OneMinuteInMillis);
      millisLeft -= mins * OneMinuteInMillis;
      const seconds = Math.floor(millisLeft / OneSecondInMillis);
      return buildTimeDeltaString(hours, mins, seconds);
    }
  }
  return "";
};

/**
 * Sets the value for a query string without actually reloading the page.
 *
 * @param {string} qsValue A querystring tagged onto a URL.
 */
export const setQueryStringWithoutPageReload = (qsValue) => {
  qsValue =
    qsValue.length && !qsValue.startsWith("?") ? `?${qsValue}` : qsValue;
  const newurl =
    location.protocol + "//" + location.host + location.pathname + qsValue;

  window.history.pushState({ path: newurl }, "", newurl);
};

/**
 * Method to get the value of a query param from its key.
 *
 * @param {string} key A query param key.
 * @returns {string} The value associated with this key.
 */
export const getQueryStringValue = (key) => {
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get(key);
};

/**
 * Sets the value for a query param key with a newValue.
 *
 * @param {string} key The query param key.
 * @param {string} newValue The new value for the key.
 */
export const setQueryStringValue = (key, newValue) => {
  const queryParams = new URLSearchParams(location.search);
  if (newValue === null) {
    queryParams.delete(key);
  } else {
    queryParams.set(key, newValue);
  }
  setQueryStringWithoutPageReload(queryParams.toString());
};

/**
 * Gets a schedule string to display to the user. Only
 * modifies things if it's a rate expression.
 *
 * @param {string} scheduleExpr A schedule expression, raw from a schedule document.
 * @returns The processed string.
 */
export const getScheduleString = (scheduleExpr) => {
  if (_.startsWith(scheduleExpr, "cron(")) {
    return "Custom Expression";
  }
  return scheduleExpr.replace("rate(", "Scan every ").replace(")", "");
};

/**
 * Gets the unit for a rate expression based on plurality.
 *
 * @param {string} value A value from the input.
 * @returns The unit either "day" or "days".
 */
export const getRateUnit = (value) => {
  if (getNumberFromString(value) == 1) {
    return "day";
  }
  return "days";
};

/**
 * Creates a random ID for list generated inputs.
 *
 * @returns A random Id of length 7 prefixed by _.
 */
export const getRandomId = () => {
  return `_${Math.random().toString(36).slice(2, 9)}`;
};

/**
 * Truncates a string to 35 characters.
 *
 * @param {string} str A string to truncate.
 * @returns The truncated string.
 */
export const getTruncated = (str) => {
  const maxLength = 23;
  if (str?.length > maxLength) {
    return `${str.slice(0, maxLength)}...`;
  }
  return str;
};

/**
 * Validates the string or object as either a bool, a bool string or null.
 *
 * @param {*} value The value to check
 * @returns A boolean indicating whether it is of the expected form or not.
 */
export const isValidBoolString = (value) => {
  if (["true", "false", true, false, null].includes(value)) {
    return true;
  }
  return false;
};

/**
 * Validates the array as a list of object Ids.
 *
 * @param {*} value The array to check.
 * @returns A boolean indicating whether it is of the expected form or not.
 */
export const isValidObjectIdList = (input) => {
  if (Symbol.iterator in Object(input)) {
    let inputLengths = [];
    for (let i = 0; i < input.length; i++) {
      inputLengths.push(isValidObjectId(input[i]));
    }
    return inputLengths.every((item) => item === true);
  }
  return false;
};

/**
 * Validates the string as an object Id.
 *
 * @param {*} string the ObjectId to check.
 * @return A boolean indicating whether it is a valid objectId or not.
 */
export const isValidObjectId = (str) => {
  return idRegex.test(str);
};

/**
 * Determines if a string is an http or https URL.
 *
 * @param {string} str The input string.
 * @returns Whether the string is a URL.
 */
export function isURL(str) {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export function formatSeverity(severity) {
  return severity === "INFORMATIONAL" ? "INFO" : severity;
}

/**
 * Determines if an Axios error is a Solis client error.
 *
 * @param {*} axiosError The Axios error to check.
 * @returns True if the error is a Solis client error. False otherwise.
 */
export const isSolisClientError = (axiosError) => {
  return (
    axiosError.response.status === 400 &&
    axiosError.response.headers["content-type"] === "application/json" &&
    "errorType" in axiosError.response.data &&
    axiosError.response.data.errorType === "InputValidationError"
  );
};

/**
 * Determines if tag has the correct characters.
 *
 * @param {string} str the input string.
 * @returns whether the string is a valid tag key.
 */
export const isValidTagKey = (str) => {
  return /^[\w\-\s]+$/.test(str);
};

/**
 * Determines if field has the correct characters.
 *
 * @param {string} str the input string.
 * @returns whether the string is a field value.
 */
export const isValidFieldString = (str) => {
  // Regex from HELIOS_NAME_REGEX in solis-tools constants.py
  return /^[a-zA-Z\d .,:&_-]+$/.test(str);
};

/**
 * Gets which destination type is in a Destination document + it's edits.
 *
 * @param {*} originalDocument The original destination Document.
 * @param {*} editingPayload The object with the new edits to apply.
 * @return A string representation of what type of destination the document + edits is.
 */
export const getDestinationType = (originalDocument, editingPayload) => {
  const type = editingPayload.type ?? originalDocument.type;
  const config = editingPayload.config ?? originalDocument.config;
  if (type === "EMAIL") return "EMAIL";
  if (type === "WEBHOOK") return "WEBHOOK";
  if (type === "WEBEX") {
    if (config?.room_id != null) return "WEBEX (Space)";
    if (config?.to_person_email != null) return "WEBEX (Direct Message)";
  }
  return "";
};

/**
 * Gets the pagination parameters from the links field of a paginated response.
 *
 * @param {*} links The links field from a paginated response.
 *
 * @return The number of pages and the current page size.
 */
export const getPaginationData = (links) => {
  if (links) {
    const params = new URL(links.last).searchParams;
    return [parseInt(params.get("page")), parseInt(params.get("pageSize"))];
  }
  // Placeholder values of 0 pages and 0 page size if the links aren't defined yet.
  return [0, 0];
};

/**
 * Trims off the end of a url based on the number of steps specified.
 *
 * @param {string} url The url to trim.
 * @param {number} step The number of steps to go backwards.
 *
 * @return The new url with the end cut off.
 */
export const getParentURL = (url, step = -1) => {
  return url.split("/").slice(0, step).join("/");
};

/**
 * Converts fields in an edit payload to the MongoDB array update format.
 *
 * @param {*} originalPayload The payload to convert.
 * @param {array} arrayFields The fields that are arrays that need to be updated.
 * @param {string} action The array action to apply to the update conversion.
 *
 * @return The updated payload with the fields in the new format.
 */
export const convertArrayUpdateFormat = (
  originalPayload,
  arrayFields,
  action = "replace"
) => {
  const arrayEdits = arrayFields
    .filter((field) => field in originalPayload)
    .reduce((prev, curr) => {
      prev[curr] = {
        action,
        entries: originalPayload[curr],
      };
      return prev;
    }, {});

  return {
    ...originalPayload,
    ...arrayEdits,
  };
};

/**
 * Checks whether the value in a field is initialized. Utility for the editor validating functions.
 *
 * @param {*} value The value to check.
 * @param {boolean} isCreating Whether this field is being created or not.
 * @param {*} emptyState The empty state of the field to check against.
 *
 * @return The updated payload with the fields in the new format.
 */
export const isFieldValueInitialized = (value, isCreating, emptyState = "") =>
  (isCreating && !value) || value === emptyState;

/**
 * Maps a header secret to a type.
 *
 * @param {*} secret The secret to derive a type from.
 *
 * @return The type the secret is.
 */
export const headerTypeMapper = (secret) =>
  secret.config_keys[0].split("=").at(-1).toLowerCase();

/**
 * Returns whether the secret is a header secret.
 *
 * @param {*} secret The secret to check.
 *
 * @return Boolean value on whether the secret is for a header.
 */
export const headerSecretFilter = (secret) =>
  secret.config_keys[0].startsWith("header=");

/**
 * Returns whether the secret is an oauth/client credential secret.
 *
 * @param {*} secret The secret to check.
 *
 * @return Boolean value on whether the secret is for an oauth secret.
 */
export const oauthSecretFilter = (secret) =>
  secret.config_keys.includes("oauth_token_url");

/**
 * Checks whether the input is an array of objects (list of dictionaries) and contains the relevant cookie fields.
 * sessionKeys constant data pulled from Playwright (the relevant cookie fields).
 *
 * @param {*} input The input to be checked as an array of objects.
 * @returns boolean true if it is an array of objects and contains relevant cookie fields false otherwise.
 */
export const isArrayOfObjectCookies = (input) => {
  return (
    Array.isArray(input) &&
    input.every(
      (item) =>
        typeof item === "object" &&
        item != null &&
        !Array.isArray(item) &&
        sessionKeys.every((key) => Object.keys(item).includes(key))
    )
  );
};

/**
 * Checks whether the inputted array is a superset of the other.
 *
 * @param {array} array1 The array that should be checked on whether it is a superset of array2.
 * @param {array} array2 The array that should be checked on whether it is a subset of array1.
 * @returns boolean true if it array 1 is a superset of array 2. Otherwise returns false.
 */
export const isSuperSet = (array1, array2) => {
  const intersection = _.intersection(array1, array2);
  return (
    intersection.length <= array1.length && intersection.length >= array2.length
  );
};

/**
 * Validates the name of the current config
 *
 * @param {string} name The name to validate.
 *
 * @returns The alert message if the name is invalid, otherwise undefined.
 */
export const validateName = (name) => {
  if (name === "") {
    return "You must give this scan configuration a name.";
  } else if (!isValidFieldString(name)) {
    return "Only alphanumerics, space characters, or a limited selection of punctuation (.,:&_-) is allowed for the scan configuration name.";
  }
};

/**
 * Adds several data structures together.
 *
 * @param {...*} counts The count objects to combine.
 *
 * @returns The counts added together. If they are incompatible returns null.
 */
export const combineCounts = (...counts) => {
  // If the types are too different, just return the first count.
  if (counts.some((count) => typeof count !== typeof counts[0])) {
    return null;
  }
  return counts.slice(1).reduce((prevCounts, currCounts) => {
    return Object.entries(prevCounts).reduce(
      (runningSum, [countKey, countVal]) => {
        const newCountVal = currCounts[countKey];
        if ((isNumber(countVal), isNumber(newCountVal))) {
          runningSum[countKey] = countVal + newCountVal;
        } else {
          runningSum[countKey] = combineCounts(countVal, newCountVal);
        }
        return runningSum;
      },
      {}
    );
  }, counts[0]);
};

/**
 * Converts a result filter to appropriate filter parameter that
 * adds an extra status filter to exclude PASS statuses if a status filter
 * isn't already explicitly defined.
 *
 * @param {object} filter The result filter to convert to a paramter.
 *
 * @returns The string URL parameter that represents the filter.
 */
export const createResultFilterParam = (filter) => {
  if ("status" in filter) {
    return encodeURIComponent(JSON.stringify(filter));
  }
  return encodeURIComponent(
    JSON.stringify({
      ...filter,
      status: {
        // Uses $in of the statuses we want instead of $ne on PASS.
        // This allows the query to use the existing indexes on status.
        $in: resultStatuses,
      },
    })
  );
};

/**
 * Extracts the special values in the error message that are wrapped in
 * single quotation marks "'".
 *
 * @param {string} message The string to extract values from.
 *
 * @returns An array of extracted values.
 */
export const extractValidationMessageValues = (message) => {
  return message.match(/'(.*?)'/g).map((value) => value.replaceAll("'", ""));
};

/**
 * Removes fields that reference the secret from a config object.
 *
 * @param {object} config The configuration to remove pointers from.
 * @param {string} id The id of the secret whose pointers should be removed.
 *
 * @returns A new config with the pointers removed.
 */
export const removeConfigSecretPointers = (config, id) => {
  const secretRegex = new RegExp(
    `^App-Config-Secrets-[0-9a-fA-F]{24}.${id}.*$`
  );
  const newConfig = Object.entries(config).reduce((prev, [key, value]) => {
    if (secretRegex.test(value)) {
      prev[key] = "";
    } else {
      prev[key] = value;
    }
    return prev;
  }, {});
  return newConfig;
};

/**
 * Removes fields that reference the file from a config object.
 *
 * @param {object} config The configuration to remove pointers from.
 * @param {string} fileName The name of the file to remove.
 * @param {string} fileField The field that directly references the file.
 *
 * @returns A new config with the pointers removed.
 */
export const removeConfigFilePointer = (config, fileName, fileField) => {
  const externalFileRegex = new RegExp(`^${fileName}:.*${fileName}$`);
  const newConfig = { ...config };
  newConfig["external_files"] = newConfig["external_files"].filter(
    (file) => !externalFileRegex.test(file)
  );

  if (_.isArray(newConfig[fileField])) {
    newConfig[fileField] = newConfig[fileField].filter(
      (file) => !file.endsWith(fileName)
    );
  } else {
    newConfig[fileField] = "";
  }

  return newConfig;
};
